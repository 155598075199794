
export const videos = {
  title: "Video Library",
  subtitle: "Watch our educational videos and tutorials",
  
  filters: {
    all: "All Videos",
    latest: "Latest Videos"
  },

  empty: {
    title: "No videos available",
    description: "Check back later for new content"
  },

  loading: "Loading videos..."
};
