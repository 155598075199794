
import { useEffect, useState } from "react";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { BillingOverview } from "@/components/billing/BillingOverview";
import { PaymentHistory } from "@/components/billing/PaymentHistory";
import { BillingAddress } from "@/components/billing/BillingAddress";
import { CancelSubscription } from "@/components/billing/CancelSubscription";
import { UpdateCard } from "@/components/billing/UpdateCard";
import { useTranslation } from "react-i18next";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle, CheckCircle, Search, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useSubscriptionData } from "@/hooks/useSubscriptionData";
import { supabase } from "@/integrations/supabase/client";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { Skeleton } from "@/components/ui/skeleton";

const Billing = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [isRecovering, setIsRecovering] = useState(false);
  const [isMigrating, setIsMigrating] = useState(false);
  const [recoveryResult, setRecoveryResult] = useState<{success: boolean; message: string} | null>(null);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [recoveryEmail, setRecoveryEmail] = useState<string>('');
  const [showEmailRecovery, setShowEmailRecovery] = useState(false);
  const [recoveryAttempted, setRecoveryAttempted] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  
  const { 
    subscription, 
    isLoading: subscriptionLoading, 
    refetch,
    recoverSubscription
  } = useSubscriptionData({
    refetchInterval: 10000,
    enableAutoRecovery: true,
    includeTxHistory: true
  });
  
  useEffect(() => {
    if (!subscriptionLoading && !initialLoadComplete) {
      setInitialLoadComplete(true);
      console.log('[Billing Page] Initial subscription load complete:', { hasSubscription: !!subscription });
      
      if (subscription) {
        refetch();
      }
    }
  }, [subscriptionLoading, subscription, initialLoadComplete, refetch]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        
        if (user) {
          console.log('[Billing Page] Current authenticated user:', user.email);
          
          const { data, error } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', user.id)
            .single();
            
          if (!error && data) {
            setUserProfile(data);
            console.log('[Billing Page] User profile loaded:', {
              hasProfile: true,
              hasTempReference: !!data.temp_user_reference,
              tempReference: data.temp_user_reference
            });
            
            setRecoveryEmail(user.email || '');
            
            if (initialLoadComplete && data.temp_user_reference && !subscription && !subscriptionLoading && !isRecovering && !recoveryResult && !recoveryAttempted) {
              console.log('[Billing Page] Found temp_user_reference but no subscription, attempting automatic recovery...');
              handleRecoverSubscription(data.temp_user_reference);
            }
          } else {
            console.error('[Billing Page] Error fetching user profile:', error);
          }
        }
      } catch (error) {
        console.error('[Billing Page] Error in fetchUserProfile:', error);
      }
    };
    
    fetchUserProfile();
  }, [subscription, subscriptionLoading, isRecovering, recoveryResult, recoveryAttempted, initialLoadComplete]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    
    if (initialLoadComplete && !subscription && !subscriptionLoading) {
      timeoutId = setTimeout(async () => {
        console.log('[Billing Page] No subscription found after timeout, trying direct query');
        
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (user) {
            const { data, error } = await supabase
              .from('subscriptions')
              .select('*')
              .eq('user_id', user.id)
              .eq('user_type', 'profile')
              .maybeSingle();
              
            if (error) {
              console.error('[Billing Page] Direct subscription query error:', error);
            } else if (data) {
              console.log('[Billing Page] Found subscription via direct query:', data);
              toast({
                title: "Subscription Found",
                description: "We found your subscription information. Refreshing data...",
              });
              refetch();
            }
          }
        } catch (error) {
          console.error('[Billing Page] Error in fallback direct query:', error);
        }
      }, 3000);
    }
    
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [initialLoadComplete, subscription, subscriptionLoading, toast, refetch]);

  const handleMigrateSubscription = async () => {
    try {
      setIsMigrating(true);
      setRecoveryResult(null);
      
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      console.log('[Billing Page] Starting subscription migration process...');
      
      const { data, error } = await supabase.functions.invoke(
        'migrate-user-subscription',
        {
          body: {
            userId: user.id,
            userEmail: user.email
          }
        }
      );
      
      if (error) {
        throw new Error(`Migration error: ${error.message}`);
      }
      
      console.log('[Billing Page] Migration result:', data);
      
      setRecoveryResult({
        success: data.success,
        message: data.message
      });
      
      if (data.success) {
        toast({
          title: "Subscription Updated",
          description: data.message || "We found and updated your subscription information.",
        });
        
        console.log('[Billing Page] Migration successful, refetching data in 1 second');
        setTimeout(() => {
          refetch();
        }, 1000);
      } else {
        toast({
          title: "Subscription Migration Failed",
          description: data.message || "We couldn't find or update your subscription.",
          variant: "destructive"
        });
      }
      
    } catch (error: any) {
      console.error('[Billing Page] Migration error:', error);
      setRecoveryResult({
        success: false,
        message: error.message || 'An unexpected error occurred'
      });
      
      toast({
        title: "Error",
        description: error.message || "An unexpected error occurred during subscription migration.",
        variant: "destructive"
      });
    } finally {
      setIsMigrating(false);
    }
  };

  const handleRecoverSubscription = async (tempUserId?: string) => {
    try {
      setIsRecovering(true);
      setRecoveryResult(null);
      setRecoveryAttempted(true);
      
      console.log('[Billing Page] Starting subscription recovery process with params:', {
        tempUserId,
        email: recoveryEmail || userProfile?.email
      });
      
      const result = await recoverSubscription({
        tempUserId,
        email: recoveryEmail || userProfile?.email
      });
      
      console.log('[Billing Page] Recovery result:', result);
      setRecoveryResult(result);
      
    } catch (error: any) {
      console.error('[Billing Page] Recovery error:', error);
      setRecoveryResult({
        success: false,
        message: error.message || 'An unexpected error occurred'
      });
    } finally {
      setIsRecovering(false);
    }
  };

  const handleEmailRecovery = async () => {
    if (!recoveryEmail) {
      setRecoveryResult({
        success: false,
        message: 'Please enter an email address'
      });
      return;
    }
    
    console.log('[Billing Page] Starting email-based recovery with:', recoveryEmail);
    await handleRecoverSubscription();
  };

  const handleRetryRecovery = () => {
    setRecoveryAttempted(false);
    setRecoveryResult(null);
    refetch();
    console.log('[Billing Page] Recovery state reset for retry');
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
          <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
            <div className="w-full">
              <h1 className="text-2xl font-semibold text-gray-900">{t('billing.title', 'Billing & Subscription')}</h1>
              <p className="text-sm text-gray-500 mb-8">{t('billing.subtitle', 'Manage your subscription and payment details')}</p>
              {subscription?.trial && (
                <div className="mt-3 mb-6 inline-block px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium">
                  {t('billing.trial.status', 'Trial Subscription')}
                </div>
              )}
              
              {subscriptionLoading && !initialLoadComplete && (
                <div className="mb-6">
                  <Skeleton className="h-12 w-full mb-4" />
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-3/4" />
                    <Skeleton className="h-4 w-1/2" />
                  </div>
                </div>
              )}
              
              {initialLoadComplete && !subscription && !subscriptionLoading && (
                <div className="mb-6">
                  <Alert variant="warning" className="bg-yellow-50 border-yellow-200">
                    <AlertCircle className="h-4 w-4 text-yellow-700" />
                    <AlertDescription className="text-yellow-700">
                      {userProfile?.temp_user_reference 
                        ? t('billing.partialSubscription.message', 'We detected partial subscription information. Click to recover it.') 
                        : t('billing.noSubscription.message', 'We could not find an active subscription for your account.')}
                    </AlertDescription>
                  </Alert>
                  
                  <div className="mt-4 space-y-4">
                    {userProfile?.temp_user_reference ? (
                      <div className="space-y-2">
                        <Button 
                          onClick={() => handleRecoverSubscription(userProfile?.temp_user_reference)}
                          disabled={isRecovering}
                          variant="outline"
                          className="flex items-center gap-2"
                        >
                          {isRecovering ? t('billing.recovering', 'Recovering...') : t('billing.recoverSubscription', 'Recover Subscription')}
                          {isRecovering && <RefreshCw className="h-4 w-4 animate-spin" />}
                        </Button>
                        
                        <Button
                          onClick={handleMigrateSubscription}
                          disabled={isMigrating}
                          variant="default"
                          className="flex items-center gap-2 ml-2"
                        >
                          {isMigrating ? "Migrating..." : "Force Migration"}
                          {isMigrating && <RefreshCw className="h-4 w-4 animate-spin" />}
                        </Button>
                        
                        {recoveryAttempted && !recoveryResult?.success && (
                          <Button
                            onClick={handleRetryRecovery}
                            variant="secondary"
                            className="ml-2"
                          >
                            Try Again with Different Method
                          </Button>
                        )}
                      </div>
                    ) : (
                      <>
                        <Button 
                          onClick={() => setShowEmailRecovery(!showEmailRecovery)}
                          variant="outline"
                          className="flex items-center gap-2"
                        >
                          <Search className="h-4 w-4" />
                          {showEmailRecovery ? 'Hide Email Recovery' : 'Find Subscription by Email'}
                        </Button>
                        
                        <Button
                          onClick={handleMigrateSubscription}
                          disabled={isMigrating}
                          variant="default"
                          className="flex items-center gap-2 ml-2"
                        >
                          {isMigrating ? "Migrating..." : "Force Migration"}
                          {isMigrating && <RefreshCw className="h-4 w-4 animate-spin" />}
                        </Button>
                        
                        {showEmailRecovery && (
                          <div className="flex flex-col gap-2 p-4 border rounded-md bg-white">
                            <p className="text-sm text-gray-500">
                              Enter the email address used during checkout to find your subscription:
                            </p>
                            <div className="flex gap-2">
                              <Input 
                                placeholder="Email address" 
                                value={recoveryEmail}
                                onChange={(e) => setRecoveryEmail(e.target.value)}
                              />
                              <Button 
                                onClick={handleEmailRecovery}
                                disabled={isRecovering || !recoveryEmail}
                              >
                                {isRecovering ? 'Searching...' : 'Search'}
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    
                    {recoveryResult && (
                      <Alert 
                        variant={recoveryResult.success ? "default" : "destructive"}
                        className={recoveryResult.success ? "bg-green-50 border-green-200" : ""}
                      >
                        {recoveryResult.success ? (
                          <CheckCircle className="h-4 w-4 text-green-600" />
                        ) : (
                          <AlertCircle className="h-4 w-4" />
                        )}
                        <AlertDescription className={recoveryResult.success ? "text-green-700" : ""}>
                          {recoveryResult.message}
                        </AlertDescription>
                      </Alert>
                    )}
                  </div>
                </div>
              )}
              
              <div className="grid gap-8 md:grid-cols-2">
                <BillingOverview />
                <BillingAddress />
              </div>
              
              <div className="grid gap-8 md:grid-cols-2">
                <UpdateCard />
                <CancelSubscription />
              </div>
              
              <PaymentHistory />
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Billing;
