
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { Card } from "@/components/ui/card";
import { Video, ExternalLink } from "lucide-react";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

interface Video {
  id: string;
  title: string;
  description: string | null;
  video_url: string;
  language: string;
}

const Videos = () => {
  const { t, i18n } = useTranslation();
  const [videoErrors, setVideoErrors] = useState<Record<string, boolean>>({});
  
  const { data: session } = useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      const { data } = await supabase.auth.getSession();
      return data.session;
    },
  });

  const { data: videos, isLoading } = useQuery({
    queryKey: ['videos', i18n.language],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('videos')
        .select('*')
        .eq('language', i18n.language)
        .order('created_at', { ascending: true });

      if (error) throw error;
      return data as Video[];
    },
  });

  if (!session) {
    return <Navigate to="/login" />;
  }

  const handleVideoError = (videoId: string) => {
    setVideoErrors(prev => ({ ...prev, [videoId]: true }));
    console.error(`YouTube video ${videoId} failed to load. Check Content Security Policy`);
  };

  const getYoutubeVideoId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
          <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
            <div className="w-full">
              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
                  <Video className="h-5 w-5 text-[#F97316]" />
                  {t('videos.title')}
                </h2>
                <p className="text-sm text-gray-500 mb-6">{t('videos.subtitle')}</p>

                {isLoading ? (
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {[1, 2, 3].map((i) => (
                      <Card key={i} className="p-6 animate-pulse">
                        <div className="aspect-video bg-gray-200 mb-4"></div>
                        <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                        <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                      </Card>
                    ))}
                  </div>
                ) : videos?.length ? (
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {videos.map((video) => {
                      const videoId = getYoutubeVideoId(video.video_url);
                      const watchUrl = videoId ? `https://www.youtube.com/watch?v=${videoId}` : video.video_url;
                      
                      return (
                        <Card key={video.id} className="overflow-hidden">
                          <div className="aspect-video">
                            {!videoErrors[video.id] ? (
                              <iframe
                                src={video.video_url}
                                title={video.title}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="w-full h-full"
                                onError={() => handleVideoError(video.id)}
                                sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-presentation"
                              />
                            ) : (
                              <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                                <a 
                                  href={watchUrl} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="flex items-center gap-2 text-primary hover:text-primary/80"
                                >
                                  <ExternalLink className="h-4 w-4" />
                                  Watch on YouTube
                                </a>
                              </div>
                            )}
                          </div>
                          <div className="p-4">
                            <h3 className="font-semibold text-gray-900 mb-2">{video.title}</h3>
                            {video.description && (
                              <p className="text-sm text-gray-500">{video.description}</p>
                            )}
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                ) : (
                  <div className="bg-white p-8 rounded-lg shadow-sm border border-gray-100 text-center">
                    <h3 className="text-lg font-medium mb-2">{t('videos.empty.title')}</h3>
                    <p className="text-gray-500">{t('videos.empty.description')}</p>
                  </div>
                )}
              </section>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Videos;
