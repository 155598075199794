
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-[#221F26] text-white py-20 px-4">
      <div className="container mx-auto max-w-6xl">
        <div className="grid md:grid-cols-4 gap-12">
          <div className="space-y-4">
            <h3 className="text-xl font-semibold mb-6">{t('landing.footer.company.title')}</h3>
            <ul className="space-y-3">
              <li><a href="#" className="hover:text-primary transition-colors">{t('landing.footer.company.about')}</a></li>
              <li><a href="#" className="hover:text-primary transition-colors">{t('landing.footer.company.contact')}</a></li>
              <li><a href="#" className="hover:text-primary transition-colors">{t('landing.footer.company.careers')}</a></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="text-xl font-semibold mb-6">{t('landing.footer.resources.title')}</h3>
            <ul className="space-y-3">
              <li><a href="#" className="hover:text-primary transition-colors">{t('landing.footer.resources.blog')}</a></li>
              <li><a href="#" className="hover:text-primary transition-colors">{t('landing.footer.resources.caseStudies')}</a></li>
              <li><a href="#" className="hover:text-primary transition-colors">{t('landing.footer.resources.faqs')}</a></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="text-xl font-semibold mb-6">{t('landing.footer.legal.title')}</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/privacy-policy" className="hover:text-primary transition-colors">
                  {t('landing.footer.legal.privacy')}
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="hover:text-primary transition-colors">
                  {t('landing.footer.legal.terms')}
                </Link>
              </li>
              <li>
                <Link to="/cookie-policy" className="hover:text-primary transition-colors">
                  {t('landing.footer.legal.cookies')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="space-y-6">
            <h3 className="text-xl font-semibold mb-6">{t('landing.footer.contact.title')}</h3>
            <p className="text-white/80">{t('landing.footer.contact.description')}</p>
            <Button className="bg-primary hover:bg-primary/90 w-full">
              {t('landing.footer.contact.cta')}
            </Button>
          </div>
        </div>
        <div className="border-t border-white/10 mt-16 pt-8 text-center text-white/60">
          <p>{t('landing.footer.copyright')}</p>
        </div>
      </div>
    </footer>
  );
};
