
import React from "react";

export const PrivacyPolicyContent = () => {
  return (
    <div className="prose max-w-none">
      <h1>Privacy Policy</h1>
      
      <p>Last updated: June 1, 2023</p>
      
      <h2>1. Introduction</h2>
      <p>Welcome to Woobound Marketing ("we," "our," or "us"). We are committed to protecting your privacy and personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services.</p>
      
      <h2>2. Information We Collect</h2>
      <p>We may collect information about you in various ways, including:</p>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, phone number, billing information, and other information you provide when creating an account or using our services.</li>
        <li><strong>Business Information:</strong> Details about your business, including business name, address, industry, size, and other relevant information.</li>
        <li><strong>Usage Data:</strong> Information about how you use our platform, including features accessed, time spent, and actions taken.</li>
        <li><strong>Technical Data:</strong> IP address, browser type, device information, cookies, and other tracking technologies.</li>
      </ul>
      
      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>Providing and maintaining our services</li>
        <li>Improving and personalizing user experience</li>
        <li>Processing transactions and managing your account</li>
        <li>Communicating with you about our services, updates, and promotional offers</li>
        <li>Analyzing usage patterns to enhance our services</li>
        <li>Protecting our services and preventing fraudulent activity</li>
      </ul>
      
      <h2>4. Information Sharing and Disclosure</h2>
      <p>We may share your information with:</p>
      <ul>
        <li><strong>Service Providers:</strong> Third-party vendors who perform services on our behalf, such as payment processing, data analysis, and customer service.</li>
        <li><strong>Business Partners:</strong> Companies we partner with to offer integrated services or promotions.</li>
        <li><strong>Legal Requirements:</strong> When required by law, to protect our rights, or in response to a legal process.</li>
      </ul>
      
      <h2>5. Data Security</h2>
      <p>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
      
      <h2>6. Your Choices and Rights</h2>
      <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
      <ul>
        <li>Accessing and updating your information</li>
        <li>Requesting deletion of your information</li>
        <li>Opting out of marketing communications</li>
        <li>Data portability</li>
        <li>Objecting to certain processing of your data</li>
      </ul>
      
      <h2>7. Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar tracking technologies to collect information about your browsing activities. You can manage your cookie preferences through your browser settings.</p>
      
      <h2>8. Children's Privacy</h2>
      <p>Our services are not directed to individuals under the age of 16. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us immediately.</p>
      
      <h2>9. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>
      
      <h2>10. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at:</p>
      <p>Email: privacy@woobound.com</p>
      <p>Address: 123 Marketing Street, Digital City, CA 94103</p>
    </div>
  );
};
