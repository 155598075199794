
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { User } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";

interface Profile {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  role: string | null;
  phone_number: string | null;
}

export const PersonalInfoForm = () => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const { toast } = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProfile = async () => {

      try {


        const arrayObjectQury =  await testQuery1();
        const arrayObjectJson = JSON.parse(JSON.stringify(arrayObjectQury, null, 2));
        const arrayObject = [ arrayObjectJson.data];
  
  
        console.log("testQueryHkam123:   ",arrayObject);
        // Extract the first object from the array
        const { data = arrayObject[0], error = null } = arrayObject.length > 0 ? { data: arrayObject[0], error: null } : { data: null, error: "No data found" };

        if (error) throw error;
        setProfile(data);
      } catch (error: any) {
        toast({
          title: "Error",
          description: error.message,
          variant: "destructive",
        });
      }
    };

    fetchProfile();
  }, [toast]);
  
  async function testQuery1() {
    try {
      var authTokenString = localStorage.getItem('sb-bzyguzdxfvnzcddghqfl-auth-token');

      // Check if the auth token exists and parse it to an object
      if (authTokenString) {

        var authToken = JSON.parse(authTokenString);
        // Now you can safely access the user property
        console.log('User111:', authToken.user.id);
        const userId1= authToken.user.id;
  
        const supabaseUrl = "https://bzyguzdxfvnzcddghqfl.supabase.co";
        const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ6eWd1emR4ZnZuemNkZGdocWZsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzM4NjczOTksImV4cCI6MjA0OTQ0MzM5OX0.tW7_0ToMEHJe-ROPvPJjI1ThtkAkt92dYxuhXnAh6aM";
        const tableName = "profiles";
        const userId = userId1; // Replace with dynamic ID
    
        const headers = {
          "Content-Type": "application/json",
          "apikey": supabaseKey,
          "Authorization": `Bearer ${supabaseKey}`,
        };
    
        const url = `${supabaseUrl}/rest/v1/${tableName}?id=eq.${userId}`;
    
        const response = await fetch(url, { method: "GET", headers: headers });
        const data = await response.json(); // ✅ Wait for JSON conversion
    
        const transformedObject = {
          data: data.length > 0 ? data[0] : null,
          error: null,
        };
    
        console.log("[testQuery] Profile fetched:", transformedObject);
        return transformedObject; // ✅ Return data
      } else {
        console.log('Auth token not found');
      }

  
    } catch (err) {
      console.error("[testQuery] Unexpected error:", err);
      return { data: null, error: err };
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!profile) return;

    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      const { error } = await supabase
        .from('profiles')
        .update({
          first_name: profile.first_name,
          last_name: profile.last_name,
          phone_number: profile.phone_number,
        })
        .eq('id', user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: t('account.personalInfo.success'),
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!profile) return <div className="w-full">Loading...</div>;

  return (
    <Card className="w-full p-6 bg-white shadow-sm border border-gray-100">
      <div className="flex items-center gap-3 mb-8">
        <div className="p-3 rounded-full bg-primary/10">
          <User className="h-6 w-6 text-primary" />
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-900">{t('account.personalInfo.title')}</h2>
          <p className="text-sm text-gray-500">{t('account.personalInfo.subtitle')}</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6 w-full">
        <div className="grid md:grid-cols-2 gap-6 w-full">
          <div className="space-y-2 w-full">
            <Label htmlFor="firstName" className="text-sm font-medium text-gray-700">
              {t('account.personalInfo.firstName')}
            </Label>
            <Input
              id="firstName"
              value={profile.first_name || ''}
              onChange={(e) => setProfile({ ...profile, first_name: e.target.value })}
              placeholder={t('account.personalInfo.firstName')}
              className="border-gray-200 focus:border-primary focus:ring-primary w-full"
            />
          </div>

          <div className="space-y-2 w-full">
            <Label htmlFor="lastName" className="text-sm font-medium text-gray-700">
              {t('account.personalInfo.lastName')}
            </Label>
            <Input
              id="lastName"
              value={profile.last_name || ''}
              onChange={(e) => setProfile({ ...profile, last_name: e.target.value })}
              placeholder={t('account.personalInfo.lastName')}
              className="border-gray-200 focus:border-primary focus:ring-primary w-full"
            />
          </div>
        </div>

        <div className="space-y-2 w-full">
          <Label htmlFor="phoneNumber" className="text-sm font-medium text-gray-700">
            {t('account.personalInfo.phoneNumber')}
          </Label>
          <Input
            id="phoneNumber"
            value={profile.phone_number || ''}
            onChange={(e) => setProfile({ ...profile, phone_number: e.target.value })}
            placeholder={t('account.personalInfo.phoneNumber')}
            type="tel"
            className="border-gray-200 focus:border-primary focus:ring-primary w-full"
          />
        </div>

        <div className="space-y-2 w-full">
          <Label htmlFor="email" className="text-sm font-medium text-gray-700">
            {t('account.personalInfo.email')}
          </Label>
          <Input
            id="email"
            value={profile.email || ''}
            disabled
            className="bg-gray-50 border-gray-200 w-full"
          />
          <p className="text-sm text-gray-500">{t('account.personalInfo.emailHelp')}</p>
        </div>

        <div className="space-y-2 w-full">
          <Label htmlFor="role" className="text-sm font-medium text-gray-700">
            {t('account.personalInfo.role')}
          </Label>
          <Input
            id="role"
            value={profile.role || ''}
            disabled
            className="bg-gray-50 border-gray-200 w-full"
          />
        </div>

        <div className="pt-4">
          <Button 
            type="submit" 
            className="w-full md:w-auto bg-primary hover:bg-primary/90"
            disabled={loading}
          >
            {loading ? t('account.personalInfo.saving') : t('account.personalInfo.saveChanges')}
          </Button>
        </div>
      </form>
    </Card>
  );
};
