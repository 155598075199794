
import React from "react";

export const TermsOfServiceContent = () => {
  return (
    <div className="prose max-w-none">
      <h1>Terms of Service</h1>
      
      <p>Last updated: June 1, 2023</p>
      
      <h2>1. Introduction</h2>
      <p>Welcome to Woobound Marketing. These Terms of Service ("Terms") govern your access to and use of the Woobound Marketing platform, website, and services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms.</p>
      
      <h2>2. Account Registration</h2>
      <p>To access certain features of our Services, you may need to register for an account. When you register, you agree to provide accurate, current, and complete information about yourself and your business. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
      
      <h2>3. Subscription and Payments</h2>
      <p>3.1. <strong>Subscription Plans:</strong> We offer various subscription plans with different features and pricing. You agree to pay all fees associated with your selected plan.</p>
      <p>3.2. <strong>Payment Terms:</strong> All payments are due in advance. We accept major credit cards and other payment methods as specified on our website.</p>
      <p>3.3. <strong>Automatic Renewal:</strong> Subscriptions automatically renew at the end of each billing period unless canceled before the renewal date.</p>
      <p>3.4. <strong>Price Changes:</strong> We reserve the right to change our prices at any time. If we do so, we will provide notice of the change before it takes effect.</p>
      
      <h2>4. User Conduct</h2>
      <p>You agree not to:</p>
      <ul>
        <li>Use our Services in any way that violates applicable laws or regulations</li>
        <li>Infringe on the intellectual property rights of others</li>
        <li>Interfere with or disrupt the Services or servers or networks connected to the Services</li>
        <li>Attempt to gain unauthorized access to any portion of the Services</li>
        <li>Use the Services to distribute malware, spyware, or other harmful code</li>
        <li>Engage in any activity that could harm our reputation or business</li>
      </ul>
      
      <h2>5. Intellectual Property Rights</h2>
      <p>5.1. <strong>Our Content:</strong> All content, features, and functionality of our Services, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, data compilations, and software, are owned by Woobound Marketing or our licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
      <p>5.2. <strong>Your Content:</strong> You retain ownership of any content you upload to our Services. By uploading content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and display your content solely for the purpose of providing the Services to you.</p>
      
      <h2>6. Termination</h2>
      <p>We may terminate or suspend your account and access to our Services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Services, us, or third parties, or for any other reason.</p>
      
      <h2>7. Disclaimer of Warranties</h2>
      <p>THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.</p>
      
      <h2>8. Limitation of Liability</h2>
      <p>IN NO EVENT SHALL WOOBOUND MARKETING BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES.</p>
      
      <h2>9. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law provisions.</p>
      
      <h2>10. Changes to These Terms</h2>
      <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page and updating the "Last Updated" date.</p>
      
      <h2>11. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <p>Email: terms@woobound.com</p>
      <p>Address: 123 Marketing Street, Digital City, CA 94103</p>
    </div>
  );
};
