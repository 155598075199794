
import React from "react";

export const CookiePolicyContent = () => {
  return (
    <div className="prose max-w-none">
      <h1>Cookie Policy</h1>
      
      <p>Last updated: June 1, 2023</p>
      
      <h2>1. Introduction</h2>
      <p>This Cookie Policy explains how Woobound Marketing ("we," "our," or "us") uses cookies and similar technologies when you visit our website or use our services. This policy provides you with information about how we use these technologies, why we use them, and your choices regarding their use.</p>
      
      <h2>2. What Are Cookies?</h2>
      <p>Cookies are small text files that are stored on your device (computer, tablet, or mobile) when you visit websites. They are widely used to make websites work more efficiently and provide information to the website owners. Cookies can be "first-party cookies" (set by us) or "third-party cookies" (set by others).</p>
      
      <h2>3. Types of Cookies We Use</h2>
      <p>We use several types of cookies for different purposes:</p>
      
      <h3>3.1. Essential Cookies</h3>
      <p>These cookies are necessary for the website to function properly. They enable core functionality such as security, network management, and account access. You cannot opt out of these cookies.</p>
      
      <h3>3.2. Performance Cookies</h3>
      <p>These cookies collect information about how visitors use our website, such as which pages they visit most often and if they receive error messages. This information helps us improve our website and your browsing experience.</p>
      
      <h3>3.3. Functionality Cookies</h3>
      <p>These cookies allow our website to remember choices you make (such as your language preference or login information) and provide enhanced, more personal features.</p>
      
      <h3>3.4. Targeting/Advertising Cookies</h3>
      <p>These cookies are used to deliver advertisements more relevant to you and your interests. They are also used to limit the number of times you see an advertisement and help measure the effectiveness of advertising campaigns.</p>
      
      <h2>4. Third-Party Cookies</h2>
      <p>We may allow third parties to place cookies on your device when you visit our website. These third parties may include analytics providers, advertising networks, and social media platforms. These cookies help them collect information about your interaction with our website and other websites.</p>
      
      <h2>5. How Long Do Cookies Stay on My Device?</h2>
      <p>Cookies can remain on your device for varying periods:</p>
      <ul>
        <li><strong>Session Cookies:</strong> These are temporary and are deleted when you close your browser.</li>
        <li><strong>Persistent Cookies:</strong> These remain on your device until they expire or you delete them.</li>
      </ul>
      
      <h2>6. Your Cookie Choices</h2>
      <p>You have several options to control or limit how we and our partners use cookies:</p>
      <ul>
        <li><strong>Browser Settings:</strong> Most browsers allow you to control cookies through their settings. These settings are typically found in the "options," "preferences," or "settings" menu of your browser.</li>
        <li><strong>Opt-Out Links:</strong> Many advertising networks provide you with ways to opt out of targeted advertising.</li>
        <li><strong>Cookie Consent Tool:</strong> When you first visit our website, you may be presented with a cookie banner that allows you to accept or decline non-essential cookies.</li>
      </ul>
      
      <h2>7. The Impact of Disabling Cookies</h2>
      <p>If you choose to disable cookies, please note that some features of our website may not function properly. For example, you may not be able to log in to your account or use certain interactive features.</p>
      
      <h2>8. Changes to This Cookie Policy</h2>
      <p>We may update this Cookie Policy from time to time to reflect changes in technology, regulation, or our business practices. Any changes will be posted on this page with an updated "Last Updated" date.</p>
      
      <h2>9. Contact Us</h2>
      <p>If you have any questions about our use of cookies or this Cookie Policy, please contact us at:</p>
      <p>Email: privacy@woobound.com</p>
      <p>Address: 123 Marketing Street, Digital City, CA 94103</p>
    </div>
  );
};
