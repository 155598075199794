
import BusinessInfoForm from "@/components/business/BusinessInfoForm";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const BusinessProfile = () => {
  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
          <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
            <div className="w-full">
              <BusinessInfoForm />
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default BusinessProfile;
