
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Info } from "lucide-react";
import { useTranslation } from "react-i18next";

export function TrendsGuide() {
  const { t } = useTranslation("googletrends");
  
  return (
    <Alert className="w-full shadow-sm">
      <Info className="h-4 w-4" />
      <AlertTitle>{t('howToRead.title')}</AlertTitle>
      <AlertDescription>
        <p>{t('howToRead.description')}</p>
        <ul className="list-disc list-inside mt-2 space-y-1">
          <li>{t('howToRead.points.peakPopularity')}</li>
          <li>{t('howToRead.points.halfPopularity')}</li>
          <li>{t('howToRead.points.noData')}</li>
          <li>{t('howToRead.points.localOpportunities')}</li>
        </ul>
      </AlertDescription>
    </Alert>
  );
}
