
import { MapPin, Phone, Search, TrendingUp } from "lucide-react";
import { Card } from "@/components/ui/card";
import { TodoList } from "@/components/dashboard/TodoList";
import { GettingStarted } from "@/components/dashboard/GettingStarted";
import { ProfileCompletionCard } from "@/components/business/ProfileCompletionCard";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { GoogleBusinessMetrics } from "@/components/metrics/GoogleBusinessMetrics";
import { GoogleMetricsComingSoon } from "@/components/metrics/GoogleBusinessMetrics/GoogleMetricsComingSoon";
import { useIsMobile } from "@/hooks/use-mobile";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const Dashboard = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const isMobile = useIsMobile();

  const { data: businessProfile, isLoading } = useQuery({
    queryKey: ['businessProfile'],
    queryFn: async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw new Error('Authentication failed');
        }

        if (!session?.user?.id) {
          console.error('No authenticated user found');
          throw new Error('Authentication required');
        }

        const { data, error } = await supabase
          .from('business_profiles')
          .select('*')
          .eq('user_id', session.user.id)
          .single();

        if (error) {
          console.error('Error fetching business profile:', error);
          
          // Only show toast for actual errors, not just missing data
          if (error.code !== 'PGRST116') {
            toast({
              title: "Error",
              description: "There was an error loading your business profile",
              variant: "destructive",
            });
          }
          throw error;
        }

        return data;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  });

  if (isLoading) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen w-full bg-[#F9F9FB]">
          <AppSidebar />
          <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
            <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
              <div className="w-full">
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </SidebarProvider>
    );
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
          <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
            <div className="w-full">
              <div className="flex justify-between items-center">
                <div>
                  <h1 className="text-3xl font-bold text-gray-900">{t('dashboard.title')}</h1>
                  <p className="text-gray-500 mt-1">
                    Welcome back! Here's your business performance overview.
                  </p>
                </div>
              </div>

              {/* Getting Started Section - Full Width */}
              <GettingStarted />

              {/* Profile Completion and Tasks - Two columns on desktop, single column on mobile */}
              <div className={`grid gap-6 ${isMobile ? 'grid-cols-1' : 'md:grid-cols-2'}`}>
                {businessProfile && (
                  <ProfileCompletionCard profile={businessProfile} />
                )}
                <TodoList />
              </div>

              {/* Google Metrics Section */}
              <div className="mt-8">
                {businessProfile?.location_id ? (
                  <GoogleBusinessMetrics locationId={businessProfile.location_id} />
                ) : (
                  <GoogleMetricsComingSoon />
                )}
              </div>

              {/* Business Updates and Keywords Section */}
              <div className="mt-8 grid gap-6 md:grid-cols-2">
                <Card className="p-6 bg-white shadow-sm hover:shadow-md transition-shadow">
                  <h2 className="text-xl font-semibold mb-4 text-gray-900">Recent Business Updates</h2>
                  <div className="space-y-3">
                    <div className="text-gray-500">No recent updates</div>
                  </div>
                </Card>

                <Card className="p-6 bg-white shadow-sm hover:shadow-md transition-shadow">
                  <h2 className="text-xl font-semibold mb-4 text-gray-900">Top Keywords</h2>
                  <div className="space-y-3">
                    <div className="text-gray-500">No keywords data available</div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Dashboard;
