
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { BusinessTable } from "@/components/admin/BusinessTable";
import { ArticleAnalytics } from "@/components/admin/ArticleAnalytics";
import { ResourceManagement } from "@/components/admin/ResourceManagement";
import { ClientBillingManager } from "@/components/admin/ClientBillingManager";
import { PartnerCodeAnalytics } from "@/components/admin/partner-codes/PartnerCodeAnalytics";
import { PaymentMonitoring } from "@/components/admin/PaymentMonitoring";
import { UserManagement } from "@/components/admin/UserManagement";
import { AdminHeader } from "@/components/admin/AdminHeader";
import WebsiteCredentialsTable from "@/components/admin/WebsiteCredentialsTable";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const AdminPanel = () => {
  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
          <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
            <div className="w-full space-y-8">
              <AdminHeader />
              
              {/* Full width tabs navigation */}
              <div className="w-full">
                <Tabs defaultValue="businesses" className="w-full">
                  <TabsList className="w-full justify-start mb-6 overflow-x-auto flex-nowrap">
                    <TabsTrigger value="users">Users</TabsTrigger>
                    <TabsTrigger value="businesses">Businesses</TabsTrigger>
                    <TabsTrigger value="credentials">Website Credentials</TabsTrigger>
                    <TabsTrigger value="articles">Articles</TabsTrigger>
                    <TabsTrigger value="resources">Resources</TabsTrigger>
                    <TabsTrigger value="billing">Client Billing</TabsTrigger>
                    <TabsTrigger value="partner-codes">Partner Codes</TabsTrigger>
                    <TabsTrigger value="payment-monitoring">Payment Events</TabsTrigger>
                  </TabsList>
                  
                  <TabsContent value="users">
                    <UserManagement />
                  </TabsContent>
                  
                  <TabsContent value="businesses">
                    <BusinessTable />
                  </TabsContent>
                  
                  <TabsContent value="credentials">
                    <WebsiteCredentialsTable />
                  </TabsContent>
                  
                  <TabsContent value="articles">
                    <ArticleAnalytics />
                  </TabsContent>
                  
                  <TabsContent value="resources">
                    <ResourceManagement />
                  </TabsContent>
                  
                  <TabsContent value="billing">
                    <ClientBillingManager />
                  </TabsContent>
                  
                  <TabsContent value="partner-codes">
                    <PartnerCodeAnalytics />
                  </TabsContent>
                  
                  <TabsContent value="payment-monitoring">
                    <PaymentMonitoring />
                  </TabsContent>
                </Tabs>
              </div>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default AdminPanel;
