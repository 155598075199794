
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import { AuthProvider } from "@/contexts/AuthContext";
import { SessionProvider } from "@/components/routing/SessionProvider";
import { ProtectedRoute } from "@/components/routing/ProtectedRoute";

// Import all pages
import ArticlePage from "@/pages/ArticlePage";
import Resources from "@/pages/Resources";
import AdminPanel from "@/pages/AdminPanel";
import Dashboard from "@/pages/Dashboard";
import Landing from "@/pages/Landing";
import MyAccount from "@/pages/MyAccount";
import BusinessProfile from "@/pages/BusinessProfile";
import Integrations from "@/pages/Integrations";
import Billing from "@/pages/Billing";
import Settings from "@/pages/Settings";
import Support from "@/pages/Support";
import Login from "@/pages/Login";
import ChatSignupPage from "@/pages/ChatSignupPage";
import Videos from "@/pages/Videos";
import Partner from "@/pages/Partner";
import PartnerLesson from "@/pages/PartnerLesson";
import Academy from "@/pages/Academy";
import Course from "@/pages/academy/Course";
import CourseLesson from "@/pages/academy/CourseLesson";
import CourseManagement from "@/pages/academy/CourseManagement";
import GoogleTrends from "@/pages/GoogleTrends";
import GoogleConnectPage from "@/pages/GoogleConnectPage";
import MarketingDashboard from "@/pages/MarketingDashboard";
import ReportsPage from "@/pages/MarketingDashboard";
import CompleteProfile from "@/pages/CompleteProfile";
import Reviews from "@/pages/Reviews";
import CaseStudies from "@/pages/CaseStudies";
import FreeAudit from "@/pages/FreeAudit";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import TermsOfService from "@/pages/TermsOfService";
import CookiePolicy from "@/pages/CookiePolicy";
import NotFound from "@/pages/NotFound";

function App() {
  return (
    <Router>
      <AuthProvider>
        <SessionProvider>
          {({ session, profile, loading }) => {
            if (loading) {
              return <div>Loading...</div>;
            }

            return (
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<Landing />} />
                <Route path="/complete-profile" element={<CompleteProfile />} />
                <Route path="/case-studies" element={<CaseStudies />} />
                <Route path="/free-audit" element={<FreeAudit />} />
                <Route path="/signup/:packageType" element={<ChatSignupPage />} />
                <Route path="/google-connect" element={<GoogleConnectPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route 
                  path="/login" 
                  element={
                    session ? (
                      profile?.is_admin ? (
                        <Navigate to="/admin" />
                      ) : (
                        <Navigate to="/dashboard" />
                      )
                    ) : (
                      <Login />
                    )
                  } 
                />

                {/* Protected routes */}
                <Route
                  path="/resources"
                  element={
                    <ProtectedRoute session={session}>
                      <Resources />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reviews"
                  element={
                    <ProtectedRoute session={session}>
                      <Reviews />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reports"
                  element={
                    <ProtectedRoute session={session}>
                      <MarketingDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/marketing-dashboard"
                  element={
                    <ProtectedRoute session={session}>
                      <Navigate to="/reports" replace />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/google-trends"
                  element={
                    <ProtectedRoute session={session}>
                      <GoogleTrends />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute session={session}>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/articles/:articleId"
                  element={
                    <ProtectedRoute session={session}>
                      <ArticlePage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/my-account"
                  element={
                    <ProtectedRoute session={session}>
                      <MyAccount />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/business-profile"
                  element={
                    <ProtectedRoute session={session}>
                      <BusinessProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/integrations"
                  element={
                    <ProtectedRoute session={session}>
                      <Integrations />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/billing"
                  element={
                    <ProtectedRoute session={session}>
                      <Billing />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute session={session}>
                      <Settings />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/support"
                  element={
                    <ProtectedRoute session={session}>
                      <Support />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/videos"
                  element={
                    <ProtectedRoute session={session}>
                      <Videos />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/partner"
                  element={
                    <ProtectedRoute session={session} requirePartner>
                      <Partner />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/partner/lessons/:lessonId"
                  element={
                    <ProtectedRoute session={session} requirePartner>
                      <PartnerLesson />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute session={session} requireAdmin>
                      <AdminPanel />
                    </ProtectedRoute>
                  }
                />

                {/* Academy routes */}
                <Route
                  path="/academy"
                  element={
                    <ProtectedRoute session={session}>
                      <Academy />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/academy/courses/:courseId"
                  element={
                    <ProtectedRoute session={session}>
                      <Course />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/academy/courses/:courseId/lessons/:lessonId"
                  element={
                    <ProtectedRoute session={session}>
                      <CourseLesson />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/academy/manage"
                  element={
                    <ProtectedRoute session={session} requireAdmin>
                      <CourseManagement />
                    </ProtectedRoute>
                  }
                />

                {/* 404 route - must come before the catch-all route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            );
          }}
        </SessionProvider>
        <Toaster />
      </AuthProvider>
    </Router>
  );
}

export default App;
