import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle, ExternalLink, RefreshCw } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const ReportsPage = () => {
  const { toast } = useToast();
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  const { data: profile } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;
      
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();
      
      if (error) throw error;
      return data;
    },
  });

  const { data: businessProfile, isLoading } = useQuery({
    queryKey: ['businessProfile', profile?.id],
    queryFn: async () => {
      if (!profile?.id) return null;

      const { data, error } = await supabase
        .from('business_profiles')
        .select('*')
        .eq('user_id', profile.id)
        .single();
      
      if (error) {
        console.error("Error fetching business profile:", error);
        throw error;
      }
      
      console.log("Retrieved business profile:", data);
      return data;
    },
    enabled: !!profile?.id,
  });
  
  const refreshDashboard = () => {
    setIsRefreshing(true);
    
    setTimeout(() => {
      setIsRefreshing(false);
      
      toast({
        title: "Dashboard Refreshed",
        description: "Your marketing dashboard has been refreshed.",
      });
    }, 1000);
  };

  if (isLoading) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen w-full bg-[#F9F9FB]">
          <AppSidebar />
          <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
            <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
              <div className="w-full">
                <div className="animate-pulse space-y-4">
                  <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </SidebarProvider>
    );
  }

  if (!businessProfile?.dashboard_url) {
    return (
      <SidebarProvider>
        <div className="flex min-h-screen w-full bg-[#F9F9FB]">
          <AppSidebar />
          <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
            <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
              <div className="w-full">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 mb-6">Reports Dashboard</h1>
                <div className="bg-white p-8 rounded-lg shadow-sm border border-gray-100">
                  <div className="flex flex-col items-center justify-center gap-4">
                    <div className="rounded-full bg-orange-100 p-3">
                      <AlertCircle className="h-6 w-6 text-orange-500" />
                    </div>
                    <h3 className="text-lg font-medium">Your dashboard is being prepared</h3>
                    <p className="text-gray-500 text-center max-w-md">
                      Our team is currently building your personalized reports dashboard. 
                      We'll notify you when it's ready to view your marketing performance metrics.
                    </p>
                    <div className="mt-4 w-full max-w-md">
                      <Skeleton className="h-8 w-full mb-2" />
                      <Skeleton className="h-24 w-full mb-2" />
                      <Skeleton className="h-32 w-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </SidebarProvider>
    );
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
          <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
            <div className="w-full">
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900">Reports Dashboard</h1>
                <Button 
                  onClick={refreshDashboard}
                  variant="outline"
                  className="flex items-center gap-2"
                  disabled={isRefreshing}
                >
                  <RefreshCw className={`h-4 w-4 ${isRefreshing ? "animate-spin" : ""}`} />
                  Refresh Dashboard
                </Button>
              </div>
              
              <Card className="w-full overflow-hidden">
                <CardHeader className="bg-gray-50 border-b border-gray-100">
                  <CardTitle>Your Marketing Performance</CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="relative w-full" style={{ height: "700px" }}>
                    {isRefreshing && (
                      <div className="absolute inset-0 bg-white bg-opacity-80 flex items-center justify-center z-10">
                        <div className="flex flex-col items-center gap-2">
                          <RefreshCw className="h-8 w-8 text-primary animate-spin" />
                          <p className="text-sm font-medium">Refreshing your dashboard...</p>
                        </div>
                      </div>
                    )}
                    <iframe 
                      src={businessProfile.dashboard_url} 
                      title="Marketing Dashboard"
                      className="w-full h-full border-0"
                      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                      loading="lazy"
                    />
                  </div>
                </CardContent>
              </Card>
              
              <div className="mt-4 flex justify-end">
                <Button 
                  variant="outline" 
                  size="sm"
                  className="flex items-center gap-1 text-gray-500"
                  onClick={() => window.open(businessProfile.dashboard_url, '_blank', 'noopener,noreferrer')}
                >
                  <ExternalLink className="h-3.5 w-3.5" />
                  Open in new tab
                </Button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default ReportsPage;
