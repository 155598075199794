
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { CookiePolicyContent } from "@/components/legal/CookiePolicyContent";

const CookiePolicy = () => {
  return (
    <SidebarProvider>
      <div className="flex min-h-screen bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 overflow-x-auto md:pl-[12rem]">
          <div className="max-w-4xl mx-auto p-8">
            <div className="bg-white rounded-lg shadow-sm p-8">
              <CookiePolicyContent />
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default CookiePolicy;
