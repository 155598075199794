
import { useTranslation } from "react-i18next";
import { ContactForm } from "@/components/support/ContactForm";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";

const Support = () => {
  const { t } = useTranslation();

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-[#F9F9FB]">
        <AppSidebar />
        <main className="flex-1 w-full overflow-x-auto md:pl-[12rem]">
          <div className="container mx-auto max-w-7xl px-4 sm:px-6 py-6">
            <div className="w-full">
              <div className="mb-8">
                <h1 className="text-2xl font-semibold text-gray-900">{t('support.title', 'Support')}</h1>
                <p className="text-sm text-gray-500">{t('support.subtitle', 'Get help with your account')}</p>
              </div>
              
              <div className="bg-white rounded-lg shadow-sm p-6 md:p-8">
                <ContactForm />
              </div>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Support;
